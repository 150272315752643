import React from 'react';
import './Spinner.scss';

const Spinner = () => (
	<div className="nci-spinner">
		<div className="spinkit spinner">
			<div className="dot1"></div>
			<div className="dot2"></div>
		</div>
	</div>
);

export default Spinner;
